import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
import { Author } from "../../components/author"

const HowToEffectivelyUseUgcInInstagramAndTwitterCampaignsTipsAndTrapsToAvoid = ({
    location
  }) => {
  const title =
    "キャンペーンと好相性！UGCの活用方法や注意点について解説【Twitter/Instagram】"
  const list = [
    { title: "ブログ", link: "/blog" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="キャンペーンと好相性！UGCの活用方法や注意点について解説【Twitter/Instagram】"
          pagedesc="「UGC」とはユーザーがSNS上で投稿した写真や動画、テキストなどのコンテンツのこと。リアルな声を公開できるため、信頼を獲得しやすく、コンバージョン率の向上が見込めます。本記事では、UGC導入までの流れやキャンペーンとの併用方法、注意点などについて解説します。"
          pageimg="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-effectively-use-ugc-in-instagram-and-twitter-campaigns-tips-and-traps-to-avoid/how-to-effectively-use-ugc-in-instagram-and-twitter-campaigns-tips-and-traps-to-avoid-1.jpg"
          pagepath="/blog/how-to-effectively-use-ugc-in-instagram-and-twitter-campaigns-tips-and-traps-to-avoid"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は3分で読むことができます
            </div>
            <h1>
              キャンペーンと好相性！UGCの活用方法や注意点について解説【Twitter/Instagram】
            </h1>
            <div className="blog__sns-container--top">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            {/* 更新日 */}
            <time className="blog__time mr-4 font-bold empty:hidden"></time>
            <time className="blog__time">公開日：2023年5月21日</time>
            <figure className="blog__thumb">
              <img
                placeholder="tracedSVG"
                alt="ハッシュタグが書かれた付箋や磁石が貼ってある壁"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-effectively-use-ugc-in-instagram-and-twitter-campaigns-tips-and-traps-to-avoid/how-to-effectively-use-ugc-in-instagram-and-twitter-campaigns-tips-and-traps-to-avoid-1.jpg"
                className="w-full"
              />
            </figure>
            <h2 id="id1">
              消費者が信頼するのは、口コミ！
            </h2>
            <p>
              ユーザーが商品のレビューなどを写真や動画、テキストで投稿したコンテンツを指す「UGC（ユージーシー）」。近年、UGCを収集して自社サイトやLPにUGCを自動で掲載できるUGC活用ツールが、注目を集めています。
            </p>
            <p>
              その背景として、商品検討のときにTwitterやInstagramの口コミを参考にするユーザーが増えていることがあげられます。また、どの広告媒体を信頼しているのか、米国の専門機関※が調査したときにも、「知人のおすすめ（92%）」と「オンラインの投稿（70%）」が上位を占めており、UGCが企業の広告やメルマガよりも信頼されやすい傾向にあります。
            </p>
            <p>※出典：9 Things We Learned from the 2018 User Generated Content (UGC) Report</p>
            <h2 id="id2">
              口コミUGCの促進、活用は必須です
            </h2>
            <p>
              そこで今回は、UGC活用ツールを利用したい企業の担当者に向けて、UGC活用ツールのメリットや導入までの流れ、さらには運用時の注意点についても解説します。Twitterキャンペーンとの併用についても説明するので、ぜひ本記事を参考にしてみてください。
            </p>
            <p>この記事は次のような人にオススメです！</p>
              <ul className="blog__border-list">
                <li>UGCをサイト等に掲載して購買促進をしたい企業の担当者</li>
                <li>ハッシュタグキャンペーンで商品のレビューを募集したいマーケティング担当者</li>
                <li>製品改善に顧客の正直なレビューを取り入れたい企業</li>
              </ul>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    消費者が信頼するのは、口コミ！
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    口コミUGCの促進、活用は必須です
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    1. UGCとは？
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    2. UGCのメリット
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id5" className="toc__link">
                        購買意欲の向上
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id6" className="toc__link">
                        ファンの獲得
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id7" className="toc__link">
                        ユーザーの利便性アップ
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id8" className="toc__link">
                        コスト削減
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id9" className="toc__link">
                        多角的視点を入手できる
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id10" className="toc__link">
                    3. UGC導入までの流れ：短期間でUGCを集めるならプレゼントキャンペーン併用がおすすめ
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id11" className="toc__link">
                        ①自動収集を行う投稿を決定する
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id12" className="toc__link">
                        ②投稿の埋め込み
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id13" className="toc__link">
                        ③収集した投稿の管理
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id14" className="toc__link">
                        ④ プレゼントキャンペーンの併用
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id15" className="toc__link">
                    4. UGC活用の注意点：キャンペーン開催時に押さえておくべきポイント
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id16" className="toc__link">
                        著作権・肖像権・商標権などに注意
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id17" className="toc__link">
                        UGCの二次利用に注意
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id18" className="toc__link">
                        利用許諾の取り方
                      </a>
                    </li>
                  </ol>
                </li>
                <li className="toc__item">
                  <a href="#id19" className="toc__link">
                    5. UGC活用にはツール導入がおすすめ
                  </a>
                  <ol className="toc__child_list">
                    <li className="toc__item">
                      <a href="#id20" className="toc__link">
                        UGCツール導入で手間とコストを大幅削減
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id21" className="toc__link">
                        低コストで使いやすいUGC活用ツールは？
                      </a>
                    </li>
                    <li className="toc__item">
                      <a href="#id22" className="toc__link">
                        PARKLoT ならSNSキャンペーンも実施できる
                      </a>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id3">1. UGCとは？</h2>
                <img
                  placeholder="tracedSVG"
                  alt="UGCのイメージ"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-effectively-use-ugc-in-instagram-and-twitter-campaigns-tips-and-traps-to-avoid/how-to-effectively-use-ugc-in-instagram-and-twitter-campaigns-tips-and-traps-to-avoid-2.png"
                  className="mb-4"
                />
              <p>
                UGCは「ユーザー生成コンテンツ(User Generated Content)」の略で、一般ユーザーがTwitterやInstagramなどのSNSに投稿したコンテンツ（写真・動画・テキストなど）を指します。
              </p>
              <p>
                UGCは実際に商品やサービスを利用した「ユーザーのリアルな声」であるため、広告や企業発信の情報よりもユーザーの信頼を得やすいのが魅力。各企業の目的に応じたコンバージョン率の向上も期待できるコンテンツです。
              </p>
              <p>
                また、有形商材に限らず、各種のサービスなど無形商材を提供するサービスにもUGCは活用可能。SNSが広く普及している現在においては、自社製品の購買を促進できる、マーケティングに必要なツールの一つといえるでしょう。
              </p>
            </div>
            <div>
              <h2 id="id4">2. UGCのメリット</h2>
              <p>
                UGCの最大の特徴は「ユーザーのリアルな声」であることです。そのため、通常の広告やプロモーションとは一味違ったメリットが存在します。
              </p>
              <h3 id="id5">購買意欲の向上</h3>
              <p>
                通常の広告と比べ、企業による押しつけ感が少ないため、敬遠されにくいのが特徴です。また、ユーザーが発信している情報なので、信頼につながり購買意欲の向上につながります。
              </p>
              <h3 id="id6">ファンの獲得</h3>
              <p>
                UGCにおいてユーザーは、ブランド・商品に関する投稿を自身でするため、そのブランドに対し一体感を感じやすいといったポイントも。その結果、商品・サービスの新たなファンを獲得することにもつながります。
              </p>
              <h3 id="id7">ユーザーの利便性アップ</h3>
              <p>
                UGCは自社サイトやLPに掲載できるので、訪れたユーザーが他のWebページをわざわざ確認するなどの手間を省けます。
              </p>
              <h3 id="id8">コスト削減</h3>
              <p>
                同じ売り上げを達成したい場合、UGCを活用すると購買につながりやすいため、他の方法を試すより低コストで実現できます。
              </p>
              <h3 id="id9">多角的視点を入手できる</h3>
              <p>
                幅広いユーザーのUGCが収集できるので、企業の担当者が気付かなかった商品の魅力や訴求ポイントを発見できます。その後の改善や新たな企画などにも反映可能です。
              </p>
              <br />
              <p>
                UGCのメリットを5つ紹介しました。今後はAIの発展により、UGCの運用にかかる工数を削減できるなど、さらなる利便性向上も期待できるツールです。他のコンテンツにはないメリットがたくさんあるので、これらのメリットも参考にUGCの導入を考えてみてください。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/twitter-hashtag-campaign"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterのハッシュタグキャンペーンとは？効果的なハッシュタグの見つけ方は？基本的なSNSマーケティング戦略を公開
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id10">3. UGC導入までの流れ：短期間でUGCを集めるならプレゼントキャンペーン併用がおすすめ</h2>
                <img
                  placeholder="tracedSVG"
                  alt="UGC導入までの流れのイメージ"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-effectively-use-ugc-in-instagram-and-twitter-campaigns-tips-and-traps-to-avoid/how-to-effectively-use-ugc-in-instagram-and-twitter-campaigns-tips-and-traps-to-avoid-3.png"
                  className="mb-4"
                />
              <p>
                UGCを収集するツールの導入を検討しているけれど、実際どのような流れで導入すればよいか分からない方もいるのではないでしょうか。まずは簡単に導入までの流れの説明をします。
              </p>
              <h3 id="id11">①自動収集を行う投稿を決定する</h3>
              <p>
                収集を行う際は、まずどのような投稿を収集するかを決定します。（例えば、特定の文言が入ったハッシュタグ付きの投稿を収集など）すると、該当するハッシュタグが入った投稿を自動的に収集できるようになります。テキストだけではなく、写真や動画などの収集も可能です。
              </p>
              <h3 id="id12">②投稿の埋め込み</h3>
              <p>
                自社サイトやLPにUGCを掲載するためのコードを埋め込みます。コードを埋め込むと、自社サイトなどで簡単にUGCを表示可能。リアルタイムの投稿を自動で掲載することもできます。
              </p>
              <h3 id="id13">③収集した投稿の管理</h3>
              <p>
                収集したコンテンツは、管理システム上で投稿内容の確認を行うことも可能です。導入するツールにUGCの表示・非表示を選択できる機能が付いていれば、投稿内容を事前に確認してから表示できるので、トラブル防止になります。
              </p>
              <h3 id="id14">④ プレゼントキャンペーンの併用</h3>
              <p>
                UGCを集めたいなら、プレゼントキャンペーンを併用するなど、インセンティブを設けるのがおすすめです。「期限までに特定のハッシュタグを付けて投稿する」といったプレゼントキャンペーンを実施すれば、多くのユーザーに注目されてUGCも増加します。
              </p>
              <br />
              <p>
                プレゼントキャンペーン以外にも、特定のハッシュタグと条件を満たした写真を掲載した投稿のなかから入賞作品を選ぶといったキャンペーンも実施できます。キャンペーンの開催にあたっては、他社の過去事例を参考にしながら、トレンドに合ったキャンペーンを実施することが重要です。
              </p>
            </div>
            <div>
              <h2 id="id15">4. UGC活用の注意点：キャンペーン開催時に押さえておくべきポイント</h2>
              <p>
                UGCの活用はマーケティング手法として効果的ではありますが、ユーザーの投稿を二次活用することから、注意しなければならない点も存在します。場合によっては、著作権違反などにつながる場合もあるので、UGCの導入を検討している場合は、ここで解説する内容を必ずチェックしておきましょう。
              </p>
              <h3 id="id16">著作権・肖像権・商標権などに注意</h3>
              <p>
                まず気をつけたいのが「著作権」「肖像権」「商標権」についてです。UGCのなかには、キャラクター・一般の人・ブランドのロゴなどが映り込んでしまっている場合があります。著作権侵害に発展する可能性もあるため、UGCを掲載する場合は事前にUGCの内容を確認し、責任をもって掲載しても問題がないかの確認を行うようにしましょう。
              </p>
              <p>
                なかには、個人を特定できるような内容の投稿がされている場合もあります。プライバシーの侵害や個人情報保護に抵触するリスクがあるので、画像をしっかり確認して個人情報に関する内容が含まれていないかをチェックするのが重要です。
              </p>
              <h3 id="id17">UGCの二次利用に注意</h3>
              <p>
                もう1点気をつけたいのが、UGCを二次利用する際に、投稿主に二次利用について利用許諾をとる必要がある点です。UGCを勝手に自社サイトなどに掲載するのはNG。トラブルを防止するためにも、投稿主に対して必ず事前に利用許諾を得るようにしましょう。
              </p>
              <h3 id="id18">利用許諾の取り方</h3>
              <p>
                利用許諾の取り方はいくつか方法がありますが、ここではよくある方法を2つ紹介します。
              </p>
              <p>
                1つ目は、キャンペーンに応募する流れのなかで利用規約を掲載する方法です。「投稿＝二次利用に承諾」とみなす旨を利用規約で定め、投稿主の同意を得ます。同意を得ないとキャンペーン応募ができない設計にすれば、実質的に利用許諾の取得を自動化でき、利用許諾を得る手間を省くことができます。
              </p>
              <p>
                2つ目の方法は、投稿主に対して直接連絡をとる方法です。UGCを多く掲載したい場合にはかなり手間がかかりますが、とにかく安全性を重視したい場合はこちらの方法を選ぶといいでしょう。
              </p>
              <br />
              <p>
                どのような方法を採用するかは企業の方針などによりますが、いずれにしてもトラブルに発展しないよう運用を行うことが重要です。
              </p>
            </div>
            <div>
              <h2 id="id19">5. UGC活用にはツール導入がおすすめ</h2>
                <img
                  placeholder="tracedSVG"
                  alt="最短３営業日で掲載を開始できます。"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/blog/how-to-effectively-use-ugc-in-instagram-and-twitter-campaigns-tips-and-traps-to-avoid/how-to-effectively-use-ugc-in-instagram-and-twitter-campaigns-tips-and-traps-to-avoid-4.png"
                  className="mb-4"
                />
              <h3 id="id20">UGCツール導入で手間とコストを大幅削減</h3>
              <p>
                UGCの活用はメリットも多く、非常に効果的なマーケティング手法ですが、自社で企画・開発から実装まで行うとなると、手間やコストがかかってしまいます。
              </p>
              <p>
                そこでおすすめなのが、事業者が提供しているUGC活用ツールを導入することです。さまざまな企業でUGCを活用したツールのサービス展開を行っているので、自社に合ったサービスを選ぶことができます。自社でイチから始めるよりコストを抑えられるのもメリットです。
              </p>
              <h3 id="id21">低コストで使いやすいUGC活用ツールは？</h3>
              <p>
                PARKLoTでは、TwitterとInstagramからUGCを収集できるUGC活用ツールを用意しています。デザインを自由に決められるのはもちろん、自社サイトやLPへのUGC埋め込みや投稿の事前にチェック機能にも対応。十分な機能を備えていながら、リーズナブルな価格を実現しています。
              </p>
              <p>
                必要最低限のコストでSNSマーケティングを強化したい企業に、PARKLoTのUGC活用ツールはおすすめです。さらに、最短3営業日で自社ホームページなどへUGC掲載が可能になるので、スピーディーに導入できます。
              </p>
              <div className="blog__border-highlight">
                <p>あわせて読みたい</p>
                <p>
                  ▶︎
                  <a
                    href="/blog/comparing-twitter-and-instagram-for-lead-generation-and-campaigns"
                    target="_blank"
                    rel="noreferrer"
                  >
                    SNS担当者必見！ツイッターとインスタグラム、集客・キャンペーンに向いているのはどっち？
                  </a>
                </p>
              </div>
            </div>
            <div>
              <h2 id="id19">PARKLoT ならSNSキャンペーンも実施できる</h2>
              <p>
                PARKLoTではUGCと相性のいいSNSキャンペーンも同時に開催できます。UGCやSNSキャンペーンを検討している担当者の方は、お気軽にPARKLoTまでご連絡ください。ヒアリングをさせていただきながら、貴社にあったサービスやプランをご紹介させていただきます。
              </p>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
            <Author name="ozaki" />
            <div className="blog__more-articles">
              <h2 className="text-center">もっと読みたい方へ</h2>
              <ul>
                <li>
                  <a
                    href="/blog/why-sns-marketing-is-necessary"
                    target="_blank"
                    rel="noreferrer"
                  >
                    なぜSNSマーケティングが必要なのか？Twitterキャンペーンの戦略について紹介
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/present-campaign-basic"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitterプレゼント企画のやり方【まずはこれだけ！テンプレ付】基本編
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/twitter-campaign-banner"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【実例20選】効果の出やすいTwitterキャンペーンバナー！現役デザイナーが徹底解説！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/customer-appeal-SNS"
                    target="_blank"
                    rel="noreferrer"
                  >
                    訴求効果ピカイチ!!
                    手軽で効果的「SNS広告」2大スタイル徹底解説～フォロー＆リツイートキャンペーンとハッシュタグキャンペーンどちらをやるべき？
                    ～
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/why-parklot-is-inexpensive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    PARKLoTはどうしてこんなに安くインスタントウィンが実施できるの？皆様の疑問にお答えします！
                  </a>
                </li>
                <li>
                  <a
                    href="/blog/how-to-get-followers-enterprise"
                    target="_blank"
                    rel="noreferrer"
                  >
                    【今すぐ実践！】Twitter企業アカウントでフォロワーを増やす方法
                  </a>
                </li>
              </ul>
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}
export default HowToEffectivelyUseUgcInInstagramAndTwitterCampaignsTipsAndTrapsToAvoid
